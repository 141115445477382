<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price" />
        <ul class="list">
          <li>
            <input type="text" class="form-control" v-model="minPrice" readonly />
          </li>
          <li><span>to</span></li>
          <li>
            <input type="text" class="form-control" v-model="price" readonly />
          </li>
        </ul>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width of the Product (mm)
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height of the Product (mm)
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth of the Product (mm)
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#efficiency-filter" role="button" aria-expanded="true"
          aria-controls="efficiency-filter">
          Energy Efficiency
        </h6>
        <div class="wrapper collapse show" id="efficiency-filter">
          <VueMultiselect v-model="energyEfficiency" :options="energyEfficiencies" :multiple="true"
            :close-on-select="true" open-direction="bottom" placeholder="Select energy efficiencies" :searchable="false"
            selectLabel="" deselectLabel="" @input="energyEfficiencyChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#control-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Type of control
        </h6>
        <div class="wrapper collapse show" id="control-filter">
          <VueMultiselect v-model="controlType" :options="controlTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select control types" :searchable="false" selectLabel=""
            deselectLabel="" @input="controlTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#connectivity-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Connectivity type
        </h6>
        <div class="wrapper collapse show" id="connectivity-filter">
          <VueMultiselect v-model="connectivity" :options="connectivities" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select connectivity types" :searchable="false" selectLabel=""
            deselectLabel="" @input="connectivityChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#access-filter" role="button" aria-expanded="true"
          aria-controls="access-filter">
          Ease of Access
        </h6>
        <div class="wrapper collapse show" id="access-filter">
          <VueMultiselect v-model="easeToAccess" :options="easeToAccesses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select ease to accesses" :searchable="false" selectLabel=""
            deselectLabel="" @input="easeToAccessChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#source-filter" role="button" aria-expanded="true"
          aria-controls="source-filter">
          Energy Sources
        </h6>
        <div class="wrapper collapse show" id="source-filter">
          <VueMultiselect v-model="energySource" :options="energySources" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select energy sources" :searchable="false" selectLabel=""
            deselectLabel="" @input="energySourceChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#plug-type-filter" role="button" aria-expanded="true"
          aria-controls="plug-type-filter">
          Plug type
        </h6>
        <div class="wrapper collapse show" id="plug-type-filter">
          <VueMultiselect v-model="plugType" :options="plugTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select plug types" :searchable="false" selectLabel="" deselectLabel=""
            @input="plugTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#cooking-mode-filter" role="button" aria-expanded="true"
          aria-controls="cooking-mode-filter">
          Cooking modes
        </h6>
        <div class="wrapper collapse show" id="cooking-mode-filter">
          <VueMultiselect v-model="cookingMode" :options="cookingModes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select cooking modes" :searchable="false" selectLabel=""
            deselectLabel="" @input="cookingModeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#cleaning-filter" role="button" aria-expanded="true"
          aria-controls="cleaning-filter">
          Integrated cleaning system
        </h6>
        <div class="wrapper collapse show" id="cleaning-filter">
          <VueMultiselect v-model="cleaningSystem" :options="cleaningSystems" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select cleaning systems" :searchable="false" selectLabel=""
            deselectLabel="" @input="cleaningSystemChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Key Features
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="Hotair4d" id="feature-0" @change="Hotair4dChanged" />
                <label for="feature-0">4D Hotair</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="addedSteam" id="feature-1" @change="addedSteamChanged" />
                <label for="feature-1">addedsteam</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="autopilot" id="feature-2" @change="autopilotChanged" />
                <label for="feature-2">Autopilot</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="Hotair3d" id="feature-3" @change="Hotair3dChanged" />
                <label for="feature-3">3D Hotair</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="meatProbe" id="feature-4" @change="meatProbeChanged" />
                <label for="feature-4">MeatProbe</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="bakingSensor" id="feature-5" @change="bakingSensorChanged" />
                <label for="feature-5">Baking Sensor</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="roastingSensor" id="feature-6" @change="roastingSensorChanged" />
                <label for="feature-6">Roasting sensor</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="interiorLight" id="feature-7" @change="interiorLightChanged" />
                <label for="feature-7">Interior light</label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#series-filter" role="button" aria-expanded="true"
          aria-controls="series-filter">
          Product series
        </h6>
        <div class="wrapper collapse show" id="series-filter">
          <VueMultiselect v-model="series" :options="serieses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select serieses" :searchable="false" selectLabel="" deselectLabel=""
            @input="seriesChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#colour-filter" role="button" aria-expanded="true"
          aria-controls="colour-filter">
          Main Colour of Product
        </h6>
        <div class="wrapper collapse show" id="colour-filter">
          <VueMultiselect v-model="color" :options="colors" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select colours" :searchable="false" selectLabel="" deselectLabel=""
            @input="colorChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#appliance-filter" role="button" aria-expanded="true"
          aria-controls="appliance-filter">
          Smart Appliance
        </h6>
        <div class="wrapper collapse show" id="appliance-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="remoteDiagnostics" id="appliance-1"
                  @change="remoteDiagnosticsChanged" />
                <label for="appliance-1">Remote Diagnostics</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "OvenFilters",
  data: function () {
    return {
      widths: [],
      colors: [],
      easeToAccesses: [],
      heights: [],
      depths: [],
      connectivities: [],
      controlTypes: [],
      energyEfficiencies: [],
      serieses: [],
      energySources: [],
      plugTypes: [],
      cookingModes: [],
      cleaningSystems: [],

      minPrice: 0,
      maxPrice: 0,

      price: 0,
      series: [],
      width: [],
      color: [],
      easeToAccess: [],
      height: [],
      depth: [],
      connectivity: [],
      controlType: [],
      energyEfficiency: [],
      energySource: [],
      plugType: [],
      cookingMode: [],
      cleaningSystem: [],
      Hotair4d: false,
      addedSteam: false,
      autopilot: false,
      Hotair3d: false,
      meatProbe: false,
      bakingSensor: false,
      roastingSensor: false,
      remoteDiagnostics: false,
      interiorLight: false,
    };
  },
  components: {
    VueMultiselect,
  },

  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    },
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_col_main",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_backofenauszug",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.easeToAccesses = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_height",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_energy_class_2010",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energyEfficiencies = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_homeconnect_connectivity",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.connectivities = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_control_setting",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.controlTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_energy_input",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energySources = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_plug_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.plugTypes = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_cooking_method",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.cookingModes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_cleaning_integrated",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.cleaningSystems = response.filter(
          (item) => !["", null].includes(item)
        );
      });
    },

    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },

    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    colorChanged() {
      let filters = {
        attr_col_main__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    easeToAccessChanged() {
      let filters = {
        attr_backofenauszug__in: _.map(this.easeToAccess, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    seriesChanged() {
      let filters = {
        series__in: _.map(this.series, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    heightChanged() {
      let filters = {
        attr_height__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    energyEfficiencyChanged() {
      let filters = {
        attr_energy_class_2010__in: _.map(this.energyEfficiency, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    controlTypeChanged() {
      let filters = {
        attr_control_setting__in: _.map(this.controlType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    connectivityChanged() {
      let filters = {
        attr_homeconnect_connectivity__in: _.map(this.connectivity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    energySourceChanged() {
      let filters = {
        attr_energy_input__in: _.map(this.energySource, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    plugTypeChanged() {
      let filters = {
        attr_plug_type__in: _.map(this.plugType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    cookingModeChanged() {
      let filters = {
        attr_cooking_method__in: _.map(this.cookingMode, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    cleaningSystemChanged() {
      let filters = {
        attr_cleaning_integrated__in: _.map(this.cleaningSystem, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    Hotair4dChanged() {
      let filters = {
        attr_cookm_4dhotair: this.Hotair4d ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    addedSteamChanged() {
      let filters = {
        attr_addedsteam: this.addedSteam ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    autopilotChanged() {
      let filters = {
        attr_autopilot: this.autopilot ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    Hotair3dChanged() {
      let filters = {
        attr_cookm_heat_hot_air: this.Hotair3d ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    meatProbeChanged() {
      let filters = {
        attr_meatprobe: this.meatProbe ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    bakingSensorChanged() {
      let filters = {
        attr_bakingsensor: this.bakingSensor ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    roastingSensorChanged() {
      let filters = {
        attr_roastingsensorplus: this.roastingSensor ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    remoteDiagnosticsChanged() {
      let filters = {
        attr_remote_diagnostics: this.remoteDiagnostics ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    interiorLightChanged() {
      let filters = {
        attr_interior_light: this.interiorLight ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },

    existingFilters() {
      this.autopilot = this.selectedFilters.attr_autopilot;
      this.remoteDiagnostics = this.selectedFilters.attr_remote_diagnostics;
      this.Hotair4d = this.selectedFilters.attr_cookm_4dhotair;
      this.addedSteam = this.selectedFilters.attr_addedsteam;
      this.Hotair3d = this.selectedFilters.attr_cookm_heat_hot_air;
      this.meatProbe = this.selectedFilters.attr_meatprobe;
      this.bakingSensor = this.selectedFilters.attr_bakingsensor;
      this.roastingSensor = this.selectedFilters.attr_roastingsensorplus;
      this.interiorLight = this.selectedFilters.attr_interior_light;

      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', "");
          sortedWidths.push(width);
        });
        this.width = sortedWidths;
      }

      if (this.selectedFilters.attr_col_main__in) {
        let sortedColors = [];
        let unsortedColors =
          this.selectedFilters.attr_col_main__in.split('","');
        unsortedColors.map((color) => {
          color = color.replaceAll('"', "");
          sortedColors.push(color);
        });
        this.color = sortedColors;
      }

      if (this.selectedFilters.attr_backofenauszug__in) {
        let sortedAccesses = [];
        let unsortedAccesses =
          this.selectedFilters.attr_backofenauszug__in.split('","');
        unsortedAccesses.map((access) => {
          access = access.replaceAll('"', "");
          sortedAccesses.push(access);
        });
        this.easeToAccess = sortedAccesses;
      }

      if (this.selectedFilters.series__in) {
        let sortedSeries = [];
        let unsortedSeries = this.selectedFilters.series__in.split('","');
        unsortedSeries.map((series) => {
          series = series.replaceAll('"', "");
          sortedSeries.push(series);
        });
        this.series = sortedSeries;
      }

      if (this.selectedFilters.attr_height__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', "");
          sortedHeights.push(height);
        });
        this.height = sortedHeights;
      }

      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }

      if (this.selectedFilters.attr_energy_class_2010__in) {
        let sortedEnergies = [];
        let unsortedEnergies =
          this.selectedFilters.attr_energy_class_2010__in.split('","');
        unsortedEnergies.map((energy) => {
          energy = energy.replaceAll('"', "");
          sortedEnergies.push(energy);
        });
        this.energyEfficiency = sortedEnergies;
      }

      if (this.selectedFilters.attr_control_setting__in) {
        let sortedControls = [];
        let unsortedControls =
          this.selectedFilters.attr_control_setting__in.split('","');
        unsortedControls.map((control) => {
          control = control.replaceAll('"', "");
          sortedControls.push(control);
        });
        this.controlType = sortedControls;
      }

      if (this.selectedFilters.attr_homeconnect_connectivity__in) {
        let sortedConnectivities = [];
        let unsortedConnectivities =
          this.selectedFilters.attr_homeconnect_connectivity__in.split('","');
        unsortedConnectivities.map((connectivity) => {
          connectivity = connectivity.replaceAll('"', "");
          sortedConnectivities.push(connectivity);
        });
        this.connectivity = sortedConnectivities;
      }

      if (this.selectedFilters.attr_energy_input__in) {
        let sortedSources = [];
        let unsortedSources =
          this.selectedFilters.attr_energy_input__in.split('","');
        unsortedSources.map((source) => {
          source = source.replaceAll('"', "");
          sortedSources.push(source);
        });
        this.energySource = sortedSources;
      }

      if (this.selectedFilters.attr_plug_type__in) {
        let sortedPlugs = [];
        let unsortedPlugs =
          this.selectedFilters.attr_plug_type__in.split('","');
        unsortedPlugs.map((plug) => {
          plug = plug.replaceAll('"', "");
          sortedPlugs.push(plug);
        });
        this.plugType = sortedPlugs;
      }

      if (this.selectedFilters.attr_cooking_method__in) {
        let sortedCookingModes = [];
        let unsortedCookingModes =
          this.selectedFilters.attr_cooking_method__in.split('","');
        unsortedCookingModes.map((mode) => {
          mode = mode.replaceAll('"', "");
          sortedCookingModes.push(mode);
        });
        this.cookingMode = sortedCookingModes;
      }

      if (this.selectedFilters.attr_cleaning_integrated__in) {
        let sortedCleaningSystems = [];
        let unsortedCleaningSystems =
          this.selectedFilters.attr_cleaning_integrated__in.split('","');
        unsortedCleaningSystems.map((system) => {
          system = system.replaceAll('"', "");
          sortedCleaningSystems.push(system);
        });
        this.cleaningSystem = sortedCleaningSystems;
      }
    },
  },
};
</script>

<style scoped></style>
